// import Vue from 'vue'
import consts from "@/consts"
import range from "lodash/range";
import {getApiProps, updateObjectByDiff} from "@/lib/lib";
// import {arraySplitIntoChunks} from "@/lib/lib";
// import {objectComparison} from "@/lib/lib";
const changedField = 'changed__time';

const collator = new Intl.Collator();
const sortByName = function (a, b) {
    let cmp = collator.compare(a?.name_ || '', b?.name_ || '')
    if (cmp) {
        return cmp;
    }
    return a.id - b.id;
}

export default {
    state: {
        unitsGroupsLiteLoad: false,
        unitsGroupsFullLoad: false,
        unitsGroups: [],
    },
    actions: {
        fetchUnitsGroups/*all*/({dispatch, getters}, args) {
            return new Promise((resolve, reject) => {
                if (!getters.apiToken) {
                    return reject(false)
                }
                dispatch('setLastCall', {name: 'fetchUnitsGroups', time: Date.now() / 1000})

                const params = getApiProps('units_groups', args)
                this.$api.unitsgroups.getAll({...params})
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            resolve(response.data)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                    })
                    .finally(() => {
                        dispatch('setLastCall', {name: 'fetchUnitsGroups', inprogress: false})
                    });
            })
        },
        fetchUnitsGroupsAll({dispatch, commit}) {
            return new Promise((resolve, reject) => {
                this.$api.init.getUnitsGroups()
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {

                            commit('setUnitsGroups', response.data)
                            commit('setUnitsGroupsLiteLoad', true)

                            resolve(true)
                        } else {
                            reject(response)
                        }
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsGroupsAll', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsGroupsAllLite({dispatch, commit, getters}) {
            return new Promise((resolve, reject) => {
                if (getters.isUnitsGroupsLiteLoad) {
                    return resolve(getters.unitsGroups.length)
                }
                dispatch('fetchUnitsGroups', {lite: true})
                    .then((data) => {
                        commit('updateUnitsGroups', data)
                        commit('setUnitsGroupsLiteLoad', true)
                        resolve(data.length)
                    })
                    .catch((error) => {
                        reject(error)
                        console.error(error);
                        setTimeout(() => {
                            dispatch('fetchUnitsGroupsAllLite', {})
                        }, 60 * 1000)
                    })
            })
        },
        fetchUnitsGroupsAllPages({dispatch, commit, getters}) {
            //dispatch('setLastCall', {name: 'fetchUnitsGroupsAll', time: Date.now() / 1000})
            dispatch('setLastCall', {name: 'fetchUnitsGroupsChanged', time: Date.now() / 1000})

            return new Promise((resolve, reject) => {
                    if (!getters.apiToken) {
                        return reject(null)
                    }
                    if (!getters.unitsGroups.length) {
                        return resolve([])
                    }
                    let pageSize = consts.querySettings.pageSize
                    let pages = Math.ceil(getters.unitsGroups.length / pageSize)
                    let fetch = range(pages).map(i => {
                        let page = i + 1;
                        return dispatch('fetchUnitsGroups', {page, 'page-size': pageSize})
                            .then((data) => {
                                commit('updateUnitsGroups', data)
                            })
                            .catch(() => {
                                dispatch('fetchUnitsGroups', {page, 'page-size': pageSize})
                            })
                    });
                    resolve(fetch)
                })
                .then((fetch) => {
                    return Promise.all(fetch)
                        .finally(() => {
                            commit('setUnitsGroupsFullLoad', true)
                        })
                })
                .then(() => {
                    //dispatch('setLastCall', {name: 'fetchUnitsGroupsAll', inprogress: false})
                    dispatch('setLastCall', {name: 'fetchUnitsGroupsChanged', inprogress: false})
                })
        },
        fetchUnitsGroupsChanged({dispatch, commit, getters}, args) {
            if (!getters.apiToken || !getters.isUnitsGroupsFullLoad) {
                return
            }
            dispatch('setLastCall', {name: 'fetchUnitsGroupsChanged', time: Date.now() / 1000})

            args = {...consts.querySettings.filter, ...args}
            return dispatch('fetchUnitsGroups', args)
                .then((data) => {
                    commit('updateUnitsGroups', data)
                    return dispatch('fetchUnitsGroups', {fields: 'id', expand: ''})
                })
                .then((data) => {
                    commit('filterUnitsGroups', data)
                })
                .finally(() => {
                    dispatch('setLastCall', {name: 'fetchUnitsGroupsChanged', inprogress: false})
                });
        },
        reloadUnitsGroupsAll({commit, dispatch}, args) {
            commit('clearUnitsGroups')
            return dispatch('fetchUnitsGroupsAllLite', args)
                .then(() => {
                    dispatch('fetchUnitsGroupsAllPages', args)
                })
        },

        saveUnitsGroup({dispatch}, unitsGroup) {
            let fn = (unitsGroup.id) ? 'updateUnitsGroup' : 'createUnitsGroup'
            return dispatch(fn, unitsGroup);
        },
        createUnitsGroup({commit, dispatch}, unitsGroup) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units_groups')
                this.$api.unitsgroups.create(unitsGroup, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsGroup', response.data)
                            dispatch('fetchUnitsGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        updateUnitsGroup({commit, dispatch}, unitsGroup) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units_groups')
                this.$api.unitsgroups.update(unitsGroup.id, unitsGroup, params)
                    .then((response) => {
                        if (response.status < 400 && !response.data?.error) {
                            commit('updateUnitsGroup', response.data)
                            dispatch('fetchUnitsGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },
        deleteUnitsGroup({commit, dispatch}, id) {
            return new Promise((resolve, reject) => {
                const params = getApiProps('units_groups')
                this.$api.unitsgroups.delete(id, {...params})
                    .then((response) => {
                        if (response.status < 400 && (!response.data || !response.data?.error)) {
                            commit('deleteUnitsGroup', id)
                            dispatch('fetchUnitsGroupsChanged')
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error)
                    });
            })
        },

    },
    mutations: {
        setUnitsGroupsLiteLoad(state, LiteLoad) {
            state.unitsGroupsLiteLoad = state.unitsGroupsLiteLoad || LiteLoad
        },
        setUnitsGroupsFullLoad(state, FullLoad) {
            state.unitsGroupsFullLoad = state.unitsGroupsFullLoad || FullLoad
        },

        setUnitsGroups(state, nUnitsGroups) {
            nUnitsGroups = nUnitsGroups.map(u => {
                if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                return u //Object.freeze(u)
            })
            nUnitsGroups.sort(sortByName)
            state.unitsGroups = nUnitsGroups
        },

        updateUnitsGroups(state, nUnitsGroups) {
            if (!state.unitsGroups.length) {
                nUnitsGroups = nUnitsGroups.map(u => {
                    if (u?.name) u.name_ = u.name.toLocaleLowerCase()
                    return u //Object.freeze(u)
                })
                nUnitsGroups.sort(sortByName)
                state.unitsGroups = nUnitsGroups
                // const chunks = arraySplitIntoChunks(nUnitsGroups)//.reverse();
                // const pushOnRenderTask = () => {
                //     if (chunks.length === 0) return;
                //     let chunk = chunks.pop();
                //     state.unitsGroups.push(...chunk);
                //     setTimeout(() => {
                //     requestAnimationFrame(pushOnRenderTask);
                //     }, 300)
                //     //this.$nextTick().then(() => pushOnRenderTask())
                // }
                // pushOnRenderTask();
                return true
            }

            nUnitsGroups.forEach(function (nUnitsGroup) {
                if (nUnitsGroup?.name) nUnitsGroup.name_ = nUnitsGroup.name.toLocaleLowerCase()
                let i = state.unitsGroups.findIndex(u => (u.id == nUnitsGroup.id))
                if (i < 0) {
                    state.unitsGroups.push(nUnitsGroup) //(Object.freeze(nUnitsGroup))
                } else
                if (!state.unitsGroupsFullLoad || state.unitsGroups[i][changedField] != nUnitsGroup[changedField]) {
                    updateObjectByDiff(state.unitsGroups[i], nUnitsGroup)
                    // delete nUnit.id
                    // nUnitsGroup = {...state.unitsGroups[i], ...nUnitsGroup}
                    // state.unitsGroups[i] = nUnitsGroup //Object.freeze(nUnitsGroup)
                }
            })
        },
        filterUnitsGroups(state, nUnitsGroups) {
            // let Ids = state.unitsGroups.map(u=> u.id)
            let nIds = nUnitsGroups.map(u => u.id)
            let removedIds = state.unitsGroups.filter(u => !nIds.includes(u.id)).map(u => u.id)
            removedIds.forEach(removedId => {
                let i = state.unitsGroups.findIndex(u => (u.id == removedId))
                if (i != -1) {
                    state.unitsGroups.splice(i, 1)
                }
            })
        },
        updateUnitsGroup(state, nUnitsGroup) {
            if (nUnitsGroup?.name) nUnitsGroup.name_ = nUnitsGroup.name.toLocaleLowerCase()
            let i = state.unitsGroups.findIndex(u => (u.id == nUnitsGroup.id))
            if (i < 0) {
                state.unitsGroups.push(nUnitsGroup) //(Object.freeze(nUnitsGroup))
            } else
            if (!state.unitsGroupsFullLoad || state.unitsGroups[i][changedField] != nUnitsGroup[changedField]) {
                updateObjectByDiff(state.unitsGroups[i], nUnitsGroup)
                // delete nUnit.id
                // nUnitsGroup = {...state.unitsGroups[i], ...nUnitsGroup}
                // state.unitsGroups[i] = nUnitsGroup //Object.freeze(nUnitsGroup)
            }
        },

        deleteUnitsGroup(state, id) {
            let i = state.unitsGroups.findIndex(u => (u.id == id))
            if (i != -1) {
                state.unitsGroups.splice(i, 1)
            }
        },

        clearUnitsGroups(state) {
            state.unitsGroups = []
            state.unitsGroupsLiteLoad = false
            state.unitsGroupsFullLoad = false
        },
    },
    getters: {
        isUnitsGroupsLiteLoad(state) {
            return state.unitsGroupsLiteLoad
        },
        isUnitsGroupsFullLoad(state) {
            return state.unitsGroupsFullLoad
        },
        unitsGroups(state) {
            // let unitsIds = state.units?.map(un => un.id) || []
            return state.unitsGroups
                // .map(g => {
                //     return {...g, units: g.units.filter(u => unitsIds.includes(u))}
                // })
        },
        unitsGroupsByIds(state) {
            // let unitsIds = state.units?.map(un => un.id) || []
            return state.unitsGroups.reduce((groupsByIds, g) => {
                groupsByIds[g.id] = g//{...g, units: g.units.filter(u => unitsIds.includes(u))}
                return groupsByIds
            }, {})
        },
        sortedUnitsGroupsIds(state) {
            let unitsGroups = state.unitsGroups
            unitsGroups.sort(sortByName)
            return unitsGroups.map(u => u.id)
        },
    }
}
